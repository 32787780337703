// 常量定义
module.exports = {
  KeyStorages: {
    KEY_USER_OPEN_ID: "_KEY_USER_OPEN_ID_",
    KEY_USER_TOKEN: "_KEY_USER_TOKEN_",
    KEY_ORGAN_ID: "_KEY_ORGAN_ID_",
    KEY_ADD_ID: "_KEY_ADD_ID_",
    KEY_IS_LOGIN: "_KEY_IS_LOGIN_",
  },
  
}