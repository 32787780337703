<!-- 我的 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="handleGoBack" />
      <div class="header-content">我的</div>
    </header>
    <div v-if="loading" class="login-loading">
      <van-loading type="spinner" vertical>
        加载中...
      </van-loading>
    </div>
    <div v-else>
      <div class="mine-module">
        <div class="mine-card">
          <div class="mine-info">
            <div>
              <img src="@/assets/image/avator.png" class="mine-img" >
            </div>
            <div class="info-module">
              <div class="mine-item">
                姓名：{{ mine_info.patient_name }}
              </div>
              <div class="mine-item">
                住院号：{{ mine_info.cure_sn }}
              </div>
              <div class="mine-item">
                科室：{{ mine_info.branch_name }} / 医生：{{ mine_info.doctor_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="opt-group">
          <div class="opt-card">
            <div class="opt-item" @click="goPrivacyPolicy">
              <div class="item-left">
                <van-icon style="margin-right:10px;" name="label-o" />
                隐私政策
              </div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
            <div class="opt-item" @click="goUserServe">
              <div class="item-left">
                <van-icon style="margin-right:10px;" name="coupon-o" />
                用户协议
              </div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
            <div class="opt-item" @click="contactUs">
              <div class="item-left">
                <van-icon style="margin-right:10px;" name="phone-o" />
                联系我们
              </div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot-opt" @click="unbindUs">
        <van-button class="unbind-btn">解除绑定</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast, Dialog } from 'vant'
import user from "@/api/user/user"
import bind from "@/api/user/bind"

export default {
  name: "mine",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      getUserInfo()
    });
    const getUserInfo = () => {
      user.userInfo({}).then(res => {
        data.loading = false
        data.mine_info = res.data
      })
    }

    const data = reactive({
      loading: true,
      mine_info: {}
    });
    const goUserServe = () => {
      $router.push({
        name: 'user-service'
      })
    }
    const goPrivacyPolicy = () => {
      $router.push({
        name: 'privacy-policy'
      })
    }
    const contactUs = () => {
      Toast('请拨打热线 \n  400-100-8500');
    }
    const handleGoBack = () => {
      $router.go(-1)
    }
    const unbindUs = () => {
      Dialog.confirm({
        message:
          '确认解除当前账号绑定病历信息？',
      })
      .then(() => {
        // on confirm
        bind.unbind({}).then(res => {
          $router.push({
            name: 'bind'
          })
        })
      })
      .catch(() => {
        // on cancel
      });
    }

    return {
      ...toRefs(data),
      contactUs,
      goUserServe,
      goPrivacyPolicy,
      handleGoBack,
      unbindUs
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  background: #EEE;
  .login-loading {
    margin-top: 120px;
    text-align: center;
  }
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    background: linear-gradient(to right,#E6F8FA, #DEFAEE);
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
}
.mine-module {
  width: 100%;
  position: relative;
  .mine-card {
    margin: 10px;
    padding: 15px;
    background: #fff;
    border-radius: 20px;
    .mine-info {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: star;
      .mine-img {
        width: 60px;
        height: 60px;
      }
      .info-module {
        font-size: 14px;
        line-height: 20px;
        margin-left: 20px;
      }
    }
  }
}
.opt-group {
  margin-top: 20px;
  width: 100%;
  .opt-card {
    margin: 10px;
    padding: 15px;
    background: #fff;
    border-radius: 20px;
    .opt-item {
      width: 100%;
      height: 45px;
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      .item-left {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
    }
  }
}
.foot-opt {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
  .unbind-btn {
    width: 88%;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #FF5511 ;
    box-shadow: 0 2px 12px 0 #bbb;
  }
}
</style>