import api from '../index'

const user = {
    // 绑定信息登录
    userInfo(data) {
        return api({
            url: 'v2/ai/user-info',
            method: 'post',
            data: data
        })
    }
}
export default user
