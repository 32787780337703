import api from '../index'

const bind = {
    // 绑定信息登录
    bindInfo(data) {
        return api({
            url: 'v2/ai/bind-info',
            method: 'post',
            data: data
        })
    },
    unbind(data) {
        return api({
            url: 'v2/ai/unbinding',
            method: 'post',
            data: data
        })
    }
}
export default bind
